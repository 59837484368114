define("ember-steps/templates/components/step-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L03mkeQo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"step\",\"hasNextStep\",\"hasPreviousStep\",\"currentStep\",\"steps\",\"transition-to\",\"transition-to-next\",\"transition-to-previous\"],[[28,\"component\",[\"step-manager/step\"],[[\"register-step\",\"remove-step\",\"update-step-node\",\"currentStep\",\"transitions\"],[[28,\"action\",[[23,0,[]],\"registerStepComponent\"],null],[28,\"action\",[[23,0,[]],\"removeStepComponent\"],null],[28,\"action\",[[23,0,[]],\"updateStepNode\"],null],[24,[\"transitions\",\"currentStep\"]],[24,[\"transitions\"]]]]],[24,[\"hasNextStep\"]],[24,[\"hasPreviousStep\"]],[24,[\"transitions\",\"currentStep\"]],[24,[\"transitions\",\"stepTransitions\"]],[28,\"action\",[[23,0,[]],\"transitionTo\"],null],[28,\"action\",[[23,0,[]],\"transitionToNext\"],null],[28,\"action\",[[23,0,[]],\"transitionToPrevious\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-steps/templates/components/step-manager.hbs"
    }
  });

  _exports.default = _default;
});