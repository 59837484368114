define("ember-place-autocomplete/initializers/register-google", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var application = arguments[1] || arguments[0];

    if (window && window.google) {
      application.register('google:main', window.google, {
        instantiate: false
      });
      application.inject('component', 'google', 'google:main');
    }
  }

  var _default = {
    name: 'register-google',
    initialize: initialize
  };
  _exports.default = _default;
});