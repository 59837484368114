define("ember-cli-bugsnag-reporter/utils/smartMerge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = smartMerge;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function smartMerge(a, b) {
    var obj = a;

    for (var option in b) {
      if (obj[option]) {
        if (_typeof(obj[option]) === 'object') {
          obj[option] = smartMerge(a[option], b[option]);
        } else {
          obj[option] = b[option];
        }
      } else {
        obj[option] = b[option];
      }
    }

    return obj;
  }
});