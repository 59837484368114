define('ember-cli-imgix/components/imgix-bg', ['exports', 'ember-get-config', 'ember-resize-aware/mixins/resize-aware', 'ember-cli-imgix/common', 'jsuri', 'imgix-core-js'], function (exports, _emberGetConfig, _resizeAware, _common, _jsuri, _imgixCoreJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var buildDebugParams = function buildDebugParams(_ref) {
    var width = _ref.width,
        height = _ref.height;

    return {
      txt64: (width != null ? width : 'auto') + ' x ' + (height != null ? height : 'auto'),
      txtalign: 'center,bottom',
      txtsize: 40,
      txtfont: 'Helvetica Neue',
      txtclr: 'ffffff',
      txtpad: 40,
      txtfit: 'max'
    };
  };

  var findNearestWidth = function findNearestWidth(actualWidth) {
    return (0, _common.findClosest)(actualWidth, _common.targetWidths);
  };

  function isDimensionInvalid(widthProp) {
    return widthProp != null && (typeof widthProp !== 'number' || widthProp <= 0);
  }

  exports.default = Ember.Component.extend(_resizeAware.default, {
    tagName: 'div',
    classNameBindings: ['elementClassNames'],
    attributeBindings: ['style', 'alt'],

    path: null, // The path to your image
    crop: null,
    fit: 'crop',
    alt: '', // img element alt attr
    options: {}, // arbitrary imgix options
    disableLibraryParam: false,

    width: null,
    height: null,
    sizes: null,
    disableSrcSet: false,

    style: Ember.computed('_src', function () {
      var src = Ember.get(this, '_src');

      var style = _extends({}, src && {
        'background-image': 'url(\'' + src + '\')',
        'background-size': 'cover'
      });
      return Ember.String.htmlSafe(Object.keys(style).map(function (key) {
        return key + ': ' + style[key];
      }).join(';'));
    }),

    _client: Ember.computed('disableLibraryParam', function () {
      if (!_emberGetConfig.default || !Ember.get(_emberGetConfig.default, 'APP.imgix.source')) {
        throw new Ember.Error('Could not find a source in the application configuration. Please configure APP.imgix.source in config/environment.js. See https://github.com/imgix/ember-cli-imgix for more information.');
      }

      var disableLibraryParam = Ember.get(_emberGetConfig.default, 'APP.imgix.disableLibraryParam') || Ember.get(this, 'disableLibraryParam');

      return new _imgixCoreJs.default({
        domain: _emberGetConfig.default.APP.imgix.source,
        includeLibraryParam: false, // to disable imgix-core-js setting ixlib=js by default
        libraryParam: disableLibraryParam ? undefined : 'ember-' + _common.constants.APP_VERSION
      });
    }),

    _src: Ember.computed('_width', '_height', 'path', '_pathAsUri', 'width', 'height', 'crop', 'fit', 'disableSrcSet', function () {
      // Warnings, checks
      if (!Ember.get(this, 'path')) {
        return;
      }
      var forcedWidth = Ember.get(this, 'width');
      var forcedHeight = Ember.get(this, 'height');
      var measuredWidth = Ember.get(this, '_width');
      var measuredHeight = Ember.get(this, '_height');

      var hasDOMDimensions = measuredWidth != null;
      if (isDimensionInvalid(forcedWidth) || isDimensionInvalid(forcedHeight)) {
        // eslint-disable-next-line no-console
        console.warn('[imgix] Either the width or height passed to this component (w: ' + forcedWidth + ', h: ' + forcedHeight + ') was invalid. Both width and height need to be a number greater than 0, or undefined.');
      }

      // Setup
      var pathAsUri = Ember.get(this, '_pathAsUri');
      var client = Ember.get(this, '_client');
      var buildWithOptions = function buildWithOptions(options) {
        return client.buildURL(pathAsUri.path(), options);
      };

      var shouldShowDebugParams = Ember.get(_emberGetConfig.default, 'APP.imgix.debug');

      var imgixOptions = Ember.get(this, 'options') || {};

      var _ref2 = function () {
        var bothWidthAndHeightPassed = forcedWidth != null && forcedHeight != null;

        if (bothWidthAndHeightPassed) {
          return { width: forcedWidth, height: forcedHeight };
        }

        if (!hasDOMDimensions) {
          return { width: undefined, height: undefined };
        }
        var ar = measuredWidth / measuredHeight;

        var neitherWidthNorHeightPassed = forcedWidth == null && forcedHeight == null;
        if (neitherWidthNorHeightPassed) {
          var _width = findNearestWidth(measuredWidth);
          var _height = Math.ceil(_width / ar);
          return { width: _width, height: _height };
        }
        if (forcedWidth != null) {
          var _height2 = Math.ceil(forcedWidth / ar);
          return { width: forcedWidth, height: _height2 };
        } else if (forcedHeight != null) {
          var _width2 = Math.ceil(forcedHeight * ar);
          return { width: _width2, height: forcedHeight };
        }
      }(),
          width = _ref2.width,
          height = _ref2.height;

      if (width == null || height == null) {
        return undefined;
      }

      var debugParams = shouldShowDebugParams ? buildDebugParams({ width: width, height: height }) : {};

      var dpr = (0, _common.toFixed)(2, Number.parseFloat(imgixOptions.dpr) || Ember.get(this, '_dpr') || 1);

      // Build base options
      var options = _extends({}, _emberGetConfig.default.APP.imgix.defaultParams || {}, {
        // Add fit from 'fit' prop
        fit: Ember.get(this, 'fit')
      }, width != null ? { w: width } : {}, height != null ? { h: height } : {}, Ember.get(this, 'crop') != null ? { crop: Ember.get(this, 'crop') } : {}, imgixOptions, debugParams, pathAsUri.queryPairs.reduce(function (memo, param) {
        memo[param[0]] = param[1];
        return memo;
      }, {}), {
        dpr: dpr
      });

      // Build src from base options
      var src = buildWithOptions(options);

      return src;
    }),

    elementClassNames: Ember.computed('config.APP.imgix.classNames', function () {
      return _emberGetConfig.default.APP.imgix.classNames || 'imgix-bg';
    }),

    didResize: function didResize(width, height) {
      if (Ember.get(this, 'path')) {
        var newWidth = Math.ceil(Ember.get(this, '_pathAsUri').getQueryParamValue('w') || width);
        var newHeight = Math.floor(Ember.get(this, '_pathAsUri').getQueryParamValue('h') || height);
        var newDpr = (0, _common.toFixed)(2, window.devicePixelRatio || 1);

        Ember.set(this, '_width', newWidth);
        Ember.set(this, '_height', newHeight);
        Ember.set(this, '_dpr', newDpr);
      }
    },


    _pathAsUri: Ember.computed('path', function () {
      if (!Ember.get(this, 'path')) {
        return false;
      }

      return new _jsuri.default(Ember.get(this, 'path'));
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.didResize(Ember.get(this, '_width') || this.element.clientWidth || this.element.parentElement.clientWidth, Ember.get(this, '_height') || this.element.clientHeight || this.element.parentElement.clientHeight);
    }
  });
});