define('ember-cli-imgix/components/imgix-image-wrapped', ['exports', 'ember-resize-aware/mixins/resize-aware', 'ember-cli-imgix/templates/components/imgix-image-wrapped', 'ember-cli-imgix/mixins/imgix-path-behavior'], function (exports, _resizeAware, _imgixImageWrapped, _imgixPathBehavior) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_resizeAware.default, _imgixPathBehavior.default, {
    layout: _imgixImageWrapped.default,
    classNames: ['imgix-image-wrap'],
    init: function init() {
      this._super.apply(this, arguments);
      Ember.deprecate('imgix-image-wrapped is deprecated and will be removed in ember-cli-imgix@3. Please migrate to using imgix-image instead.', false, {
        id: 'ember-cli-imgix/imgix-image-wrapper-deprecation',
        until: '3.0.0'
      });
    }
  });
});