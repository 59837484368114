define('ember-resize-aware/mixins/resize-aware', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var isTesting = Ember.testing;

  exports.default = Ember.Mixin.create({
    unifiedEventHandler: Ember.inject.service(),

    didResize: function didResize() /*width, height*/{},
    // Overridden in subclass
    debounceRate: 200, // Overridden in subclass

    _previousWidth: null,
    _previousHeight: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this._handleResizeEvent = this._handleResizeEvent.bind(this);
      Ember.run.scheduleOnce('afterRender', this, function () {
        return Ember.get(_this, 'unifiedEventHandler').register('window', 'resize', _this._handleResizeEvent);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.get(this, 'unifiedEventHandler').unregister('window', 'resize', this._handleResizeEvent);
    },
    _handleResizeEvent: function _handleResizeEvent() {
      Ember.run.debounce(this, this._debouncedResizeEvent, isTesting ? 0 : Ember.get(this, 'debounceRate'));
    },
    _debouncedResizeEvent: function _debouncedResizeEvent() {
      var _this2 = this;

      if (this.element) {
        var boundingRect = this.element.getBoundingClientRect();

        var newWidth = Math.floor(boundingRect.width);
        var newHeight = Math.floor(boundingRect.height);

        if (Ember.get(this, '_previousWidth') !== newWidth || Ember.get(this, '_previousHeight') !== newHeight) {
          Ember.run.next(this, function () {
            return !Ember.get(_this2, 'isDestroyed') && Ember.tryInvoke(_this2, 'didResize', [newWidth, newHeight]);
          });
          Ember.setProperties(this, {
            _previousWidth: newWidth,
            _previousHeight: newHeight
          });
        }
      }
    }
  });
});