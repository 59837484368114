define("ember-steps/templates/components/step-manager/step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VFEmoVMK",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"hasNext\",\"hasPrevious\"],[[24,[\"hasNext\"]],[24,[\"hasPrevious\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-steps/templates/components/step-manager/step.hbs"
    }
  });

  _exports.default = _default;
});