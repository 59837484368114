define("@ember/render-modifiers/modifiers/did-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
    The `{{did-update}}` element modifier is activated when any of its arguments
    are updated. It does not run on initial render.
  
    In this example, the `resize` function receives the `textarea` DOM element as its
    first argument and is executed anytime the `@text` argument changes.
  
    ```handlebars
    <textarea {{did-update this.resize @text}} readonly style="padding: 0px;">
      {{@text}}
    </textarea>
    ```
  
    ```js
    export default Component.extend({
      resize(element) {
        element.style.height = `${element.scrollHeight}px`;
      }
    });
    ```
  
    In addition to the `element`, both named and positional arguments are passed to the
    executed function:
  
    ```handlebars
    <div {{did-update this.logArguments @first @second third=@third}} />
    ```
  
    ```js
    export default Component.extend({
      logArguments(element, [first, second], { third }) {
        console.log('element', element);
        console.log('positional args', first, second);
        console.log('names args', third);
      }
    });
    ```
  
    By default, the executed function will be unbound. If you would like to access
    the component context in your function, use the `action` decorator as follows:
  
    ```handlebars
    <div {{did-update this.someFunction @someArg} />
    ```
  
    ```js
    export default Component.extend({
      someFunction: action(function(element, [someArg]) {
        // the `this` context will be the component instance
      })
    });
    ```
  
    @method did-update
    @public
  */
  var _default = Ember._setModifierManager(function () {
    return {
      capabilities: Ember._modifierManagerCapabilities('3.13'),
      createModifier: function createModifier() {
        return {
          element: null
        };
      },
      installModifier: function installModifier(state, element) {
        // save element into state bucket
        state.element = element;
      },
      updateModifier: function updateModifier(_ref, args) {
        var element = _ref.element;

        var _args$positional = _toArray(args.positional),
            fn = _args$positional[0],
            positional = _args$positional.slice(1);

        fn(element, positional, args.named);
      },
      destroyModifier: function destroyModifier() {}
    };
  }, function DidUpdateModifier() {
    _classCallCheck(this, DidUpdateModifier);
  });

  _exports.default = _default;
});