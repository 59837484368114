define('ember-cli-imgix/components/imgix-image', ['exports', 'ember-get-config', 'imgix-core-js', 'jsuri', 'ember-cli-imgix/common'], function (exports, _emberGetConfig, _imgixCoreJs, _jsuri, _common) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /**
   * Parse an aspect ratio in the format w:h to a decimal. If false is returned, the aspect ratio is in the wrong format.
   */
  function parseAspectRatio(aspectRatio) {
    if (typeof aspectRatio !== 'string') {
      return false;
    }
    var isValidFormat = function isValidFormat(str) {
      return (/^\d+(\.\d+)?:\d+(\.\d+)?$/.test(str)
      );
    };
    if (!isValidFormat(aspectRatio)) {
      return false;
    }

    var _aspectRatio$split = aspectRatio.split(':'),
        _aspectRatio$split2 = _slicedToArray(_aspectRatio$split, 2),
        width = _aspectRatio$split2[0],
        height = _aspectRatio$split2[1];

    return parseFloat(width) / parseFloat(height);
  }

  var attributeMap = _extends({
    src: 'src',
    srcset: 'srcset',
    sizes: 'sizes'
  }, Ember.get(_emberGetConfig.default, 'APP.imgix.attributeNameMap') || {});

  var buildDebugParams = function buildDebugParams(_ref) {
    var width = _ref.width,
        height = _ref.height;

    return {
      txt64: (width != null ? width : 'auto') + ' x ' + (height != null ? height : 'auto'),
      txtalign: 'center,bottom',
      txtsize: 40,
      txtfont: 'Helvetica Neue',
      txtclr: 'ffffff',
      txtpad: 40,
      txtfit: 'max'
    };
  };

  exports.default = Ember.Component.extend({
    tagName: 'img',
    classNameBindings: ['elementClassNames'],
    attributeBindings: ['alt', 'crossorigin', 'draggable', 'src:' + attributeMap.src, 'placeholderSrc:' + (attributeMap.src === 'src' ? '_src' : 'src'), 'srcset:' + attributeMap.srcset, 'sizes:' + attributeMap.sizes],

    path: null, // The path to your image
    aspectRatio: null,
    crop: null,
    fit: 'crop',
    onLoad: null,
    onError: null,
    crossorigin: 'anonymous', // img element crossorigin attr
    alt: '', // img element alt attr
    draggable: true, // img element draggable attr
    options: {}, // arbitrary imgix options
    disableLibraryParam: false,

    width: null,
    height: null,
    sizes: null,
    disableSrcSet: false,
    placeholderPath: null,

    debounceRate: 400,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'onLoad')) {
        this._handleImageLoad = this._handleImageLoad.bind(this);
        this.element.addEventListener('load', this._handleImageLoad);
      }

      if (Ember.get(this, 'onError')) {
        this._handleImageError = this._handleImageError.bind(this);
        this.element.addEventListener('error', this._handleImageError);
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (Ember.get(this, 'onLoad') && typeof FastBoot === 'undefined') {
        this.element.removeEventListener('load', this._handleImageLoad);
      }

      if (Ember.get(this, 'onError') && typeof FastBoot === 'undefined') {
        this.element.removeEventListener('error', this._handleImageError);
      }

      this._super.apply(this, arguments);
    },


    _pathAsUri: Ember.computed('path', function () {
      if (!Ember.get(this, 'path')) {
        return false;
      }

      return new _jsuri.default(Ember.get(this, 'path'));
    }),

    _client: Ember.computed('disableLibraryParam', function () {
      if (!_emberGetConfig.default || !Ember.get(_emberGetConfig.default, 'APP.imgix.source')) {
        throw new Ember.Error('Could not find a source in the application configuration. Please configure APP.imgix.source in config/environment.js. See https://github.com/imgix/ember-cli-imgix for more information.');
      }

      var disableLibraryParam = Ember.get(_emberGetConfig.default, 'APP.imgix.disableLibraryParam') || Ember.get(this, 'disableLibraryParam');

      return new _imgixCoreJs.default({
        domain: _emberGetConfig.default.APP.imgix.source,
        includeLibraryParam: false, // to disable imgix-core-js setting ixlib=js by default
        libraryParam: disableLibraryParam ? undefined : 'ember-' + _common.constants.APP_VERSION
      });
    }),

    _srcAndSrcSet: Ember.computed('path', '_pathAsUri', 'width', 'height', 'crop', 'fit', 'disableSrcSet', 'aspectRatio', function () {
      var _this = this;

      // Warnings, checks
      if (!Ember.get(this, 'path')) {
        return;
      }
      if (Ember.get(this, 'aspectRatio')) {
        Ember.deprecate('aspectRatio as a option is deprecated in favour of passing `ar` as a direct parameter to imgix. aspectRatio will be removed in ember-cli-imgix@3.', false, {
          id: 'ember-cli-imgix/aspectRatio-deprecation',
          until: '3.0.0'
        });
      }
      var widthProp = Ember.get(this, 'width');
      var heightProp = Ember.get(this, 'height');
      if (isDimensionInvalid(widthProp) || isDimensionInvalid(heightProp)) {
        // eslint-disable-next-line no-console
        console.warn('[imgix] Either the width or height passed to this component (w: ' + widthProp + ', h: ' + heightProp + ') was invalid. Both width and height need to be a number greater than 0, or undefined.');
      }

      // Setup
      var pathAsUri = Ember.get(this, '_pathAsUri');
      var disableSrcSet = Ember.get(this, 'disableSrcSet');
      var client = Ember.get(this, '_client');
      var buildWithOptions = function buildWithOptions(options) {
        return client.buildURL(pathAsUri.path(), options);
      };

      var isFixedDimensionsMode = widthProp != null || heightProp != null;

      var shouldShowDebugParams = Ember.get(_emberGetConfig.default, 'APP.imgix.debug');

      var _ref2 = function () {
        var imgixOptions = _extends({}, Ember.get(_this, 'options'));
        var deprecatedAR = Ember.get(_this, 'aspectRatio');
        var aspectRatio = deprecatedAR ? deprecatedAR + ':1' : undefined;
        if (imgixOptions.ar) {
          aspectRatio = imgixOptions.ar;
          delete imgixOptions.ar;
        }

        return { imgixOptions: imgixOptions, aspectRatio: aspectRatio };
      }(),
          imgixOptions = _ref2.imgixOptions,
          aspectRatio = _ref2.aspectRatio;

      // Calculate AR


      var aspectRatioDecimal = parseAspectRatio(aspectRatio);
      if (aspectRatio != null && aspectRatioDecimal === false) {
        // false return value from parseAspectRatio indicates invalid response
        // eslint-disable-next-line no-console
        console.warn('[imgix] The aspect ratio passed ("' + aspectRatio + '") is not in the correct format. The correct format is "W:H".');
      }

      // Ensure width and height are set correctly according to aspect ratio

      var _ref3 = function () {
        if (widthProp && heightProp && aspectRatio) {
          // eslint-disable-next-line no-console
          console.warn('[imgix] All three of width, height, and aspect ratio were passed. The aspect ratio prop has no effect in this configuration.');
        }
        if (!widthProp && !heightProp && disableSrcSet && aspectRatio) {
          // eslint-disable-next-line no-console
          console.warn('[imgix] The aspect ratio prop has no effect when when srcsets are disabled and neither width nor height are set. To use aspect ratio, please either pass a width or height value, or enable src sets.');
        }

        var neitherWidthNorHeightPassed = !(widthProp || heightProp);
        var bothWidthAndHeightPassed = widthProp && heightProp;
        var shouldReturnOriginalDimensions = neitherWidthNorHeightPassed || // we need at least one dimension to generate the other one
        bothWidthAndHeightPassed || // if both dimensions are already passed, we don't need to generate one
        !aspectRatioDecimal; // can't generate dimensions without an AR

        if (shouldReturnOriginalDimensions) {
          return { width: widthProp, height: heightProp };
        }

        if (widthProp) {
          var _height = Math.ceil(widthProp / aspectRatioDecimal);
          return { width: widthProp, height: _height };
        } else if (heightProp) {
          var _width = Math.ceil(heightProp * aspectRatioDecimal);
          return { width: _width, height: heightProp };
        } else {
          return { width: widthProp, height: heightProp };
        }
      }(),
          width = _ref3.width,
          height = _ref3.height;

      var debugParams = shouldShowDebugParams ? buildDebugParams({ width: width, height: height }) : {};

      // Build base options
      var options = _extends({}, _emberGetConfig.default.APP.imgix.defaultParams || {}, {
        // Add fit from 'fit' prop
        fit: Ember.get(this, 'fit')
      }, width != null ? { w: width } : {}, height != null ? { h: height } : {}, Ember.get(this, 'crop') != null ? { crop: Ember.get(this, 'crop') } : {}, imgixOptions, debugParams, pathAsUri.queryPairs.reduce(function (memo, param) {
        memo[param[0]] = param[1];
        return memo;
      }, {}));

      // Build src from base options
      var src = buildWithOptions(options);

      // Calculate src set (if enabled)
      var srcset = function () {
        if (disableSrcSet) {
          return;
        }

        // w-type srcsets should not be used if one of the dimensions has been fixed as it will have no effect
        if (isFixedDimensionsMode) {
          var buildWithDpr = function buildWithDpr(dpr) {
            return buildWithOptions(_extends({}, options, {
              dpr: dpr
            }));
          };
          // prettier-ignore
          return buildWithDpr(2) + ' 2x, ' + buildWithDpr(3) + ' 3x, ' + buildWithDpr(4) + ' 4x, ' + buildWithDpr(5) + ' 5x';
        } else {
          var buildSrcSetPair = function buildSrcSetPair(targetWidth) {
            var targetHeight = function () {
              var isARInvalid = !(aspectRatioDecimal && aspectRatioDecimal > 0);
              if (options.h || isARInvalid) {
                return options.h;
              }

              return Math.ceil(targetWidth / aspectRatioDecimal);
            }();

            var debugParams = shouldShowDebugParams ? buildDebugParams({ width: targetWidth, height: targetHeight }) : {};

            var urlOptions = _extends({}, options, debugParams, {
              w: targetWidth
            }, targetHeight ? { h: targetHeight } : {});
            var url = buildWithOptions(urlOptions);
            return url + ' ' + targetWidth + 'w';
          };
          var addFallbackSrc = function addFallbackSrc(srcset) {
            return srcset.concat(src);
          };

          return addFallbackSrc(_common.targetWidths.map(buildSrcSetPair)).join(', ');
        }
      }();

      return { src: src, srcset: srcset };
    }),

    src: Ember.computed('_srcAndSrcSet', function () {
      return Ember.get(this, '_srcAndSrcSet.src');
    }),
    srcset: Ember.computed('_srcAndSrcSet', function () {
      return Ember.get(this, '_srcAndSrcSet.srcset');
    }),
    placeholderSrc: Ember.computed('placeholderPath', function () {
      if (attributeMap.src === 'src') {
        return null;
      }
      var client = Ember.get(this, '_client');
      var placeholderPathURI = new _jsuri.default(Ember.get(this, 'placeholderPath'));
      var placeholderURL = client.buildURL(placeholderPathURI.path(), _extends({}, placeholderPathURI.queryPairs.reduce(function (memo, param) {
        memo[param[0]] = param[1];
        return memo;
      }, {})));
      return placeholderURL;
    }),

    elementClassNames: Ember.computed('config.APP.imgix.classNames', function () {
      return _emberGetConfig.default.APP.imgix.classNames || 'imgix-image';
    }),

    _handleImageLoad: function _handleImageLoad(event) {
      var _this2 = this;

      Ember.run.debounce(this, function () {
        return !Ember.get(_this2, 'isDestroyed') && Ember.tryInvoke(_this2, 'onLoad', [event]);
      }, 500);
    },
    _handleImageError: function _handleImageError(event) {
      var _this3 = this;

      Ember.run.debounce(this, function () {
        return !Ember.get(_this3, 'isDestroyed') && Ember.tryInvoke(_this3, 'onError', [event]);
      }, 500);
    }
  });


  function isDimensionInvalid(widthProp) {
    return widthProp != null && (typeof widthProp !== 'number' || widthProp <= 0);
  }
});