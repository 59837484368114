define('ember-cli-imgix/mixins/imgix-path-behavior', ['exports', 'imgix-core-js', 'ember-get-config', 'jsuri', 'ember-cli-imgix/common'], function (exports, _imgixCoreJs, _emberGetConfig, _jsuri, _common) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    crossorigin: null,
    aspectRatio: null,

    auto: null,
    crop: null,
    fit: null,

    pixelStep: 10,

    useParentWidth: false,

    /**
     * @public
     * @property {string} The main entry point for our component. The final `src` will be set based on a manipulation of this property.
     */
    path: null,

    /**
     * @private
     * @property {string} The computed path from the input path. This should not include any query parameters passed in, e.g. "/users/1.png?sat=100"
     */
    _path: Ember.computed('path', function () {
      var path = Ember.get(this, 'path');
      return path ? new _jsuri.default(path).path() : '';
    }),

    /**
     * @private
     * @property {Object} a hash of key-value pairs for parameters that were passed in via the `path` property
     */
    _query: Ember.computed('path', function () {
      var path = Ember.get(this, 'path');
      var query = {};

      var searchParams = new _jsuri.default(path).queryPairs;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = searchParams[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          query[item[0]] = item[1];
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return path ? Ember.Object.create(query) : {};
    }),

    _widthFromPath: Ember.computed('_query', function () {
      return Ember.get(this, '_query.w');
    }),

    _heightFromPath: Ember.computed('_query', function () {
      return Ember.get(this, '_query.h');
    }),

    /**
     * @private
     * @default 0
     * @property {number} An internal counter to used to trigger resizes.
     */
    _resizeCounter: 0,

    /**
     * The main meat of our responsive imaging. We use an instance of ImgixClient to build up a new image
     * URL based on `path` and apply the correct sizing parameters as we go.
     *
     * @public
     * @property {string}
     * @return the fully built string
     */
    src: Ember.computed('_path', '_query', '_width', '_height', '_dpr', 'crop', 'fit', function () {
      if (!Ember.get(this, '_width')) {
        return;
      }

      var env = Ember.get(this, '_config');

      // These operations are defaults and should be overidden by any incoming
      // query parameters
      var options = {
        fit: Ember.get(this, 'fit') || 'crop'
      };

      if (Ember.get(this, 'crop')) {
        Ember.merge(options, { crop: Ember.get(this, 'crop') });
      }

      if (Ember.get(this, 'auto')) {
        Ember.merge(options, { auto: Ember.get(this, 'auto') });
      }

      if (Ember.get(this, '_query')) {
        Ember.merge(options, Ember.get(this, '_query'));
      }

      if (!!env && Ember.get(env, 'APP.imgix.debug')) {
        Ember.merge(options, Ember.get(this, '_debugParams'));
      }

      // This is where the magic happens. These are the parameters that force the
      // responsiveness that we're looking for.
      Ember.merge(options, {
        w: Ember.get(this, '_width'),
        h: Ember.get(this, '_height'),
        dpr: Ember.get(this, '_dpr')
      });

      return Ember.get(this, '_client').buildURL(Ember.get(this, '_path'), options);
    }),

    /**
     * Fire off a resize after our element has been added to the DOM.
     */
    didInsertElement: function didInsertElement() {
      Ember.run.schedule('afterRender', this, this._incrementResizeCounter);
    },

    /**
     * Observer to trigger image resizes, but debounced.
     * @private
     */
    didResize: function didResize() {
      var debounceRate = 200;
      var env = Ember.get(this, '_config');
      if (!!env && !!Ember.get(env, 'APP.imgix.debounceRate')) {
        debounceRate = Ember.get(env, 'APP.imgix.debounceRate');
      }
      Ember.run.debounce(this, this._incrementResizeCounter, debounceRate);
    },

    /**
     * @property ImgixClient instantiated ImgixClient
     * @throws {EmberError} Will throw an error if the imgix config information is not found in config/environment.js
     * @return ImgixClient return an instantiated ImgixClient instance.
     */
    _client: Ember.computed(function () {
      var env = Ember.get(this, '_config');
      if (!env || !Ember.get(env, 'APP.imgix.source')) {
        throw new Ember.Error('Could not find a source in the application configuration. Please configure APP.imgix.source in config/environment.js. See https://github.com/imgix/ember-cli-imgix for more information.');
      }

      var disableLibraryParam = Ember.get(_emberGetConfig.default, 'APP.imgix.disableLibraryParam') || Ember.get(this, 'disableLibraryParam');

      return new _imgixCoreJs.default({
        domain: env.APP.imgix.source,
        includeLibraryParam: false, // to disable imgix-core-js setting ixlib=js by default
        libraryParam: disableLibraryParam ? undefined : 'ember-' + _common.constants.APP_VERSION
      });
    }),

    /**
     * Increments an internal resize counter, which will trigger an image resize.
     *
     * @private
     * @method _incrementResizeCounter
     */
    _incrementResizeCounter: function _incrementResizeCounter() {
      if (Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying')) {
        return;
      }
      this.incrementProperty('_resizeCounter');
    },

    /**
     * @property {Object}
     * @return {Object} a POJO with some extra imgix parameters to overlay debug data on our image.
     * @private
     */
    _debugParams: Ember.computed('_width', '_height', '_dpr', function () {
      return {
        txt64: Ember.get(this, '_width') + ' x ' + Ember.get(this, '_height') + ' @ DPR ' + Ember.get(this, '_dpr'),
        txtalign: 'center,bottom',
        txtsize: 20,
        txtfont: 'Helvetica Neue',
        txtclr: 'ffffff',
        txtpad: 20,
        txtfit: 'max',
        exp: -2
      };
    }),

    /**
     * Width as computed by the child image element's clientWidth
     * @private
     * @property _width
     * @default 0
     */
    _width: Ember.computed('_resizeCounter', 'pixelStep', 'useParentWidth', function () {
      var newWidth = 0;

      if (Ember.get(this, 'useParentWidth') && Ember.get(this, 'element')) {
        newWidth = this.$().parent().outerWidth();
      }

      if (!newWidth) {
        newWidth = Ember.get(this, 'element.clientWidth') || Ember.get(this, '_widthFromPath');
      }
      var pixelStep = Ember.get(this, 'pixelStep');
      return Math.ceil(newWidth / pixelStep) * pixelStep;
    }),

    /**
     * Height as computed by the child image element's clientHeight
     * @private
     * @property _height
     * @default 0
     */
    _height: Ember.computed('aspectRatio', '_resizeCounter', '_width', function () {
      var newHeight = Ember.get(this, 'element.clientHeight') || 0;

      if (Ember.get(this, 'aspectRatio')) {
        newHeight = Ember.get(this, '_width') / Ember.get(this, 'aspectRatio');
      }

      return Math.floor(newHeight);
    }),

    /**
     * Device Pixel Ratio as reported by the client.
     * @private
     * @property _dpr
     * @return {Number} devicePixelRatio for the client
     * @default 1
     */
    _dpr: Ember.computed('_resizeCounter', function () {
      return (0, _common.toFixed)(2, window.devicePixelRatio || 1);
    }),

    /**
     * Simple abstraction for reading the app's configuration. Useful for testing.
     * @private
     */
    _config: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    })
  });
});