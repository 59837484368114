/**
 * Registers
 *
 * For the most part, these follows MIPS naming conventions, however the
 * register numbers are different.
 */
var Register;

(function (Register) {
  // $0 or $pc (program counter): pointer into `program` for the next insturction; -1 means exit
  Register[Register["pc"] = 0] = "pc"; // $1 or $ra (return address): pointer into `program` for the return

  Register[Register["ra"] = 1] = "ra"; // $2 or $fp (frame pointer): pointer into the `evalStack` for the base of the stack

  Register[Register["fp"] = 2] = "fp"; // $3 or $sp (stack pointer): pointer into the `evalStack` for the top of the stack

  Register[Register["sp"] = 3] = "sp"; // $4-$5 or $s0-$s1 (saved): callee saved general-purpose registers

  Register[Register["s0"] = 4] = "s0";
  Register[Register["s1"] = 5] = "s1"; // $6-$7 or $t0-$t1 (temporaries): caller saved general-purpose registers

  Register[Register["t0"] = 6] = "t0";
  Register[Register["t1"] = 7] = "t1"; // $8 or $v0 (return value)

  Register[Register["v0"] = 8] = "v0";
})(Register || (Register = {}));

export { Register };