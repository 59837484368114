define("ember-cli-bugsnag-reporter/services/bugsnag", ["exports", "ember-cli-bugsnag-reporter/utils/smartMerge"], function (_exports, _smartMerge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    info: function info(error, metaData) {
      this.notify.call(this, error, {
        severity: "info",
        metaData: metaData
      });
    },
    warning: function warning(error, metaData) {
      this.notify.call(this, error, {
        severity: "warning",
        metaData: metaData
      });
    },
    error: function error(_error, metaData) {
      this.notify.call(this, _error, {
        severity: "error",
        metaData: metaData
      });
    },
    notify: function notify(error) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // eslint-disable-next-line no-console
      var logger = console[options.severity] || console.info;
      logger(error, this.__getOptions(options));
    },
    __getOptions: function __getOptions(options) {
      var defaultOptions = this.__getDefaultOptions();

      options = (0, _smartMerge.default)(defaultOptions, options);
      return Object.assign({}, defaultOptions, options);
    },
    __getDefaultOptions: function __getDefaultOptions() {
      var options = {
        severity: "error"
      };

      if (this.meta.getUser) {
        options.user = this.meta.getUser(Ember.getOwner(this));
      }

      if (this.meta.getMetaData) {
        options.metaData = this.meta.getMetaData(Ember.getOwner(this));
      }

      return options;
    }
  });

  _exports.default = _default;
});